'use strict';

/*******************************************************************************************/
class UxrGpList_ulNodeId extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'listDI': null,
			'itemsDI': null,
		};
		
		this.key = IdGenerator.produce('UxrGpList__');
		
		this.onDragStart = this.onDragStart.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		if (this.state.itemsDI === null) {
			return null;
		}
		
		let listItems = [];
		let o = this;
		_.forEach(this.state.itemsDI, function(eachItemDI) {
			
			let label = eachItemDI.node.id; //TODO: capabilities.humanReadableIdentificator(dataImage);
			let props = {
				key: 'listItem_for_nodeId_'+ eachItemDI.node.id,
				onClick: _.partial(o.props.onEditWish, eachItemDI.node.id),
				onDragStart: _.partial(o.onDragStart, eachItemDI),
				onDragEnd: _.partial(o.onDragEnd, eachItemDI),
				draggable: true,
			};
			props['data-drag-effect'] = 'all';
			
			listItems.push((
				<li {...props}>
					{label}
				</li>
			));
		}); //for
		
		return (
			<ul>
				{listItems}
			</ul>
		);
	}
	
	/*---------------------------------------------------------------------*/
	componentDidMount() {
		this.updateState(this.props);
		E.updateController.registerListener(this.key, this.updateListener.bind(this));
	}
	
	/*---------------------------------------------------------------------*/
	componentDidUpdate() {
	}
	
	/*---------------------------------------------------------------------*/
	componentWillReceiveProps(nextProps) {
		this.updateState(nextProps);
	}
	
	/*---------------------------------------------------------------------*/
	updateState(props) {
		let o = this;
		
		E.localCache.dataImage.get(props.listId)
			.then(function(listDI) {
				let assocItems = listDI.values.data.items;
				let itemPromises = [];
				itemPromises.push(Promise.resolve(listDI));
				_.forEach(assocItems.getAll(), function(eachAssocItem) {
					itemPromises.push(eachAssocItem.promise());
				});
				
				Promise
					.all(itemPromises)
					.then(function(items) {
						let listDI = items.shift();
						o.setState({
							'listDI': listDI,
							'itemsDI': items,
						});
					})
					;
			});
	}
	
	/*---------------------------------------------------------------------*/
	updateListener(messages) {
		let relevantNodes = {};
		[this.props.listId].forEach(function(value) {
			relevantNodes[value] = value;
		});
		_.forEach(this.state.itemsDI, function(eachItemDI) {
			let eachId = eachItemDI.node.id;
			relevantNodes[eachId] = eachId;
		});
		
		messages = messages.filter(function(message) {
			return (message.nodeId in relevantNodes);
		});
		
		if (messages.length) {
			this.updateState(this.props);
		}
	}
		/*---------------------------------------------------------------------*/
	onDragStart(dataImage, event) {
		console.log(arguments);
		
		UI.dnd = {
			'uiDonor': this.props.ui,
			'dataImageManipulated': dataImage
		}
		event.dataTransfer.dropEffect = 'link';
	}
	
	/*---------------------------------------------------------------------*/
	onDragEnd(dataImage, event) {
		delete UI.dnd;
	}
	
	/*---------------------------------------------------------------------*/
} //class
